import { URL_PARAMETERS } from "@/components/ProductListV2/constants";
import type {
  FetchDataClientSideProps,
  UseProductListProps,
} from "@/hooks/useProductList/types";
import { FILTER_SEPARATOR } from "@/react-app/constants";
import { useSessionSource } from "@/react-app/contexts/Session";
import { useSharedData } from "@/react-app/contexts/SharedData";
import { useTranslations } from "@/react-app/contexts/Translations/TranslationsContext";
import type { XXLCookie } from "@/react-app/global";
import {
  cookieNames,
  getCustomerKey,
  getFallbackCustomerKey,
  getFallbackSessionKey,
  getPreferredStoresCookie,
  getSessionKey,
} from "@/react-utils/Cookie";
import { fetchPageData } from "@/utils/search-page-helper/fetchElevateSearchDataForPage";
import type { LandingPageResponseData } from "@/utils/search-page-helper/types";
import { hasNoValue, hasValue } from "@xxl/common-utils";
import { log } from "@xxl/logging-utils";
import { useStateValue } from "cotton-box-react";
import isEqual from "lodash/isEqual";
import { useCallback, useEffect, useState } from "react";

let latestRequestId = 0;
const generateLatestRequestId = () => (latestRequestId = latestRequestId + 1);

const useProductList = (props: UseProductListProps) => {
  const {
    api,
    facetsFromServer,
    isDiscountCategoryCode,
    numberOfProductsPerPage,
    pageType,
    productsFromServer,
    stores,
    subType,
    totalHitsFromServer,
  } = props;
  const { featureToggles, siteUid } = useSharedData().data;
  const isLoggedIn = useStateValue(useSessionSource);
  const { t } = useTranslations();
  const [products, setProducts] = useState(productsFromServer);
  const [facets, setFacets] = useState(facetsFromServer);
  const [totalHits, setTotalHits] = useState(totalHitsFromServer);
  const [isFetchingProducts, setIsFetchingProducts] = useState(true);

  useEffect(() => {
    setFacets(facetsFromServer);
  }, [facetsFromServer]);

  useEffect(() => {
    setProducts(productsFromServer);
  }, [productsFromServer]);

  useEffect(() => {
    setTotalHits(totalHitsFromServer);
  }, [totalHitsFromServer]);

  const fetchDataClientSide = useCallback(
    ({
      availability,
      categoryId,
      page,
      selectedFilters,
      selectedSort,
      selectedStores,
    }: FetchDataClientSideProps) => {
      void (async () => {
        try {
          setIsFetchingProducts(true);
          const customerKey = getCustomerKey() ?? getFallbackCustomerKey();
          const sessionKey = getSessionKey() ?? getFallbackSessionKey();
          const requestId = generateLatestRequestId();
          const fakeXXLCookie: XXLCookie = { loggedIn: isLoggedIn };
          const commonArgs = {
            api,
            cookies: {
              [cookieNames.PREFERRED_STORES]: JSON.stringify(
                getPreferredStoresCookie()
              ),
              [cookieNames.XXL]: JSON.stringify(fakeXXLCookie),
            },
            featureToggles,
            headers: {},
            numberOfProductsPerPage,
            pageType,
            query: {
              [URL_PARAMETERS.availability.name]:
                `[${availability.map((a) => `"${a}"`).toString()}]`,
              ...Object.fromEntries(
                Object.entries(selectedFilters).map(([key, value]) => [
                  `${URL_PARAMETERS.facet.name}${key}`,
                  value.join(FILTER_SEPARATOR),
                ])
              ),
              [URL_PARAMETERS.page.name]: page.toString(),
              [URL_PARAMETERS.sort.name]: selectedSort,
              [URL_PARAMETERS.selectedStores.name]:
                `[${selectedStores.map((s) => `"${s}"`).toString()}]`,
            },
            siteUid,
            subType,
            stores,
            userKeys: { customerKey, sessionKey },
          };

          let data: LandingPageResponseData | null = null;

          if (subType === "category") {
            if (hasNoValue(categoryId)) {
              throw Error("Expecting categoryId to be defined");
            }
            // Selected facets are listed in commonArgs.query
            const longTailFacetsWithoutValues =
              props.longTailFacets?.map((longTailFacet) => ({
                ...longTailFacet,
                values: [],
              })) ?? null;
            data = await fetchPageData({
              ...commonArgs,
              isDiscountCategoryCode,
              longTailFacets: longTailFacetsWithoutValues,
              longTailPattern: props.longTailPattern,
              query: {
                ...commonArgs.query,
                categoryId,
              },
              subType: "category",
            });
          }

          if (subType === "campaign") {
            data = await fetchPageData({
              ...commonArgs,
              campaignIds: props.campaignIds,
              ...(hasValue(categoryId) && {
                categoryIds: [categoryId],
              }),
              subType: "campaign",
            });
          }

          if (requestId !== latestRequestId) {
            return;
          }

          if (data === null) {
            throw Error(
              `No data available. Verify that correct subType is used. Current subType: ${subType}`
            );
          }

          setIsFetchingProducts(false);

          setFacets((state) => {
            if (isEqual(state, data.facets)) {
              return state;
            }
            return data.facets;
          });

          setProducts((state) => {
            if (isEqual(state, data.products)) {
              return state;
            }
            return data.products;
          });

          setTotalHits(data.totalHits);
        } catch (error) {
          setIsFetchingProducts(false);
          log.error(error);
        }
      })();
    },
    [
      api,
      featureToggles,
      isDiscountCategoryCode,
      isLoggedIn,
      numberOfProductsPerPage,
      pageType,
      props.campaignIds,
      props.longTailFacets,
      props.longTailPattern,
      siteUid,
      stores,
      subType,
      t,
    ]
  );

  if (subType === "category") {
    return {
      facets,
      fetchDataClientSide,
      isFetchingProducts,
      products,
      longTailFacets: props.longTailFacets,
      longTailPattern: props.longTailPattern,
      totalHits,
    };
  }

  return {
    facets,
    fetchDataClientSide,
    isFetchingProducts,
    products,
    totalHits,
  };
};

export { useProductList };
