import { hasValue, isNotNullOrUndefined } from "@xxl/common-utils";
import {
  type BaseProductData,
  type ProductPageResult,
} from "@xxl/product-search-api";

/**
 * This function addresses mismatches between the data schema and the live data from the search API
 *
 * Prepare base product data for the client.
 * This function is used to prepare the base product data for the client.
 * It removes the base color from the product data and converts the campaign start and end dates to Date objects.
 *
 * @param product - The base product data.
 * @returns The prepared base product data.
 */
export const getSerializableProductData = (baseProduct: BaseProductData) => ({
  ...baseProduct,
  products: baseProduct.products.map((product) => {
    const baseColor = product.baseColor;
    delete product.baseColor;

    return {
      ...product,
      ...(hasValue(baseColor) && {
        baseColor,
      }),
      ...(isNotNullOrUndefined(product.campaign) && {
        campaign: {
          ...product.campaign,
          ...(isNotNullOrUndefined(product.campaign.start) && {
            start: JSON.parse(JSON.stringify(product.campaign.start)) as Date,
          }),
          ...(isNotNullOrUndefined(product.campaign.end) && {
            end: JSON.parse(JSON.stringify(product.campaign.start)) as Date,
          }),
        },
      }),
    };
  }),
});

export type SerializableProductPageResult =
  | null
  | (Omit<ProductPageResult, "baseProduct"> & {
      baseProduct: BaseProductData | null;
    });
export const getSerializableProductPageResult = (
  productPageResult: ProductPageResult | undefined
): SerializableProductPageResult =>
  isNotNullOrUndefined(productPageResult)
    ? {
        ...productPageResult,
        baseProduct: isNotNullOrUndefined(productPageResult.baseProduct)
          ? getSerializableProductData(productPageResult.baseProduct)
          : null,
      }
    : null;
