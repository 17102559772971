import type { PreferredStoreIds } from "@/react-utils/Cookie";
import { getPreferredStoresCookie } from "@/react-utils/Cookie";
import { DEFAULT_AVAILABILITY } from "@/react-components/Sort/AvailabilitySelector/constants";
import { AVAILABILITY } from "@/react-components/Search/SearchFetchProductsHelper.types";
import { hasValue } from "@xxl/common-utils";

const getAvailabilityAndStoreIdsFromCookieOrDefault = (
  storeIdsFromServer: PreferredStoreIds
) => {
  const {
    availability: availabilityFromCookie,
    ids: storeIds = storeIdsFromServer,
  } = getPreferredStoresCookie() ?? {};
  const availability =
    availabilityFromCookie ??
    (hasValue(storeIds) ? DEFAULT_AVAILABILITY : [AVAILABILITY.ONLINE]);

  return { availability, storeIds };
};

export { getAvailabilityAndStoreIdsFromCookieOrDefault };
