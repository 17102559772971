import { URL_PARAMETERS } from "@/components/ProductListV2/constants";
import { FILTER_SEPARATOR } from "@/react-app/constants";
import type { EcomSiteUidLegacy } from "@/react-app/global";
import type { NumberOfColumnsPerDeviceOnPlp } from "@/react-components/ProductList/hooks/useSelectedColumnsNumber/useSelectedColumnsNumber.types";
import { AVAILABILITY } from "@/react-components/Search/SearchFetchProductsHelper.types";
import type { StoreWithId } from "@/react-components/Sort/AvailabilitySelector/DialogStoreSelect";
import { DEFAULT_AVAILABILITY } from "@/react-components/Sort/AvailabilitySelector/constants";
import type { PreferredStores } from "@/react-utils/Cookie";
import { cookieNames, parseCookie } from "@/react-utils/Cookie";
import { toProductCardDataFromBase } from "@/react-utils/ProductData/product-card-data-helper";
import {
  convertSiteUidToHost,
  legacySiteUidToSiteUid,
} from "@/react-utils/xxl-shared-data";
import { hasNoValue, hasValue, isEmpty, isNotNull } from "@xxl/common-utils";
import type { LongTailData } from "@xxl/frontend-api";
import { log } from "@xxl/logging-utils";
import type {
  LandingPageQuery,
  LandingPageRequestSettings,
  LandingPageResultData,
  SearchPageQuery,
  SearchResultData,
  SortType,
  SubCategory,
} from "@xxl/product-search-api";
import { ElevateQuery, typeOfQuery } from "@xxl/product-search-api";
import type { AxiosResponse } from "axios";
import { parametersToRequest } from "../../components/ProductListV2/hooks/utils";
import { getLandingPageResults, getSearchResults } from "../apis/search-api";
import { getElevateDeviceTypeFromHeaders } from "../app-page-helper";
import { getPromiseResult } from "../get-server-side-props-helper";
import { getSerializableProductData } from "../search-api-utils/product-data-maps";
import {
  convertContentListsToTabs,
  getCachedOrFetchedStoreData,
  getChannel,
  getCorrectTabNameOrFirstOrNull,
  getIsLoggedIn,
  getPriceId,
  getSelectedFiltersFromUrlParameters,
  hasValidBreadcrumbData,
  isValidSearchQuery,
  prepareSearchSuggestions,
  sanitizeFacetsForDisplay,
} from "./search-helper";
import type {
  FetchLandingPageData,
  FetchSearchPageData,
  LandingPageResponseData,
  SearchPageResponseData,
} from "./types";

function prepareDataForClient(
  data: LandingPageResultData
): LandingPageResultData;
function prepareDataForClient(data: SearchResultData): SearchResultData;
function prepareDataForClient(
  data: LandingPageResultData | SearchResultData
): LandingPageResultData | SearchResultData {
  return {
    ...data,
    primaryList: {
      ...data.primaryList,
      baseProducts: data.primaryList.baseProducts.map(
        getSerializableProductData
      ),
    },
  };
}

const getLongTailFacets = (longTailFacets: LongTailData[]) => {
  const longTailFacetsWithValues = longTailFacets.filter(({ values }) =>
    hasValue(values)
  );
  const selectedLongTailFilters = longTailFacetsWithValues.map(
    ({ id, values }) => ({
      [`${URL_PARAMETERS.facet.name}${id}`]: values.join(FILTER_SEPARATOR),
    })
  );
  const selectedLongTailFiltersFormatted = longTailFacetsWithValues.reduce(
    (acc, { id, values }) => ({ ...acc, [id]: values }),
    {} as { [x: string]: string[] }
  );

  return {
    selectedLongTailFilters: selectedLongTailFilters.reduce(
      (acc, current) => ({ ...acc, ...current }),
      {}
    ),
    selectedLongTailFiltersFormatted,
  };
};

const fetchPage = async (
  args: (FetchLandingPageData | FetchSearchPageData) & {
    availability: AVAILABILITY[];
    validSelectedStores: string[];
    forceAnonymousUser?: boolean;
  }
): Promise<
  | {
      pageType: "search";
      responseData: AxiosResponse<SearchResultData | LandingPageResultData>;
    }
  | {
      pageType: "landing";
      responseData: AxiosResponse<LandingPageResultData>;
      subType: "campaign";
    }
  | {
      longTailFacets: LongTailData[] | null;
      longTailPattern: string | null;
      pageType: "landing";
      parentCategory: SubCategory | null;
      responseData: AxiosResponse<LandingPageResultData>;
      subType: "category";
      selectedLongTailFiltersFormatted: {
        [x: string]: string[];
      } | null;
    }
> => {
  const {
    api,
    availability,
    cookies,
    featureToggles,
    forceAnonymousUser = false,
    headers,
    numberOfProductsPerPage,
    query: pagePropsQuery,
    siteUid,
    userKeys,
    validSelectedStores,
  } = args;
  const { categoryId, pages, query, sort, tab, ...otherQueryParams } =
    pagePropsQuery;
  const { toggle_elevate_cluster_landing_page, toggle_elevate_cluster_search } =
    featureToggles;
  const { customerKey, sessionKey } = userKeys;
  const modernSiteUid = legacySiteUidToSiteUid(siteUid);

  const {
    channels,
    limit,
    skip,
    sort: sortParsed,
    storeIds: _storeIds,
    query: q,
  } = parametersToRequest({
    availability,
    numberOfProductsPerPage,
    page: typeof pages !== "string" ? "0" : pages,
    query: isValidSearchQuery(query) ? query : "",
    sort: (typeof sort !== "string" ? undefined : sort) as SortType,
    selectedStores: validSelectedStores,
  });
  const { selectedFilters } =
    getSelectedFiltersFromUrlParameters(otherQueryParams);
  const commonArgs = {
    channels: getChannel(channels),
    customerKey,
    limit,
    sessionKey,
    site: convertSiteUidToHost(modernSiteUid),
    skip,
    sort: sortParsed,
    ...(_storeIds !== undefined && {
      stores: _storeIds,
    }),
    touchpoint: getElevateDeviceTypeFromHeaders(headers),
    priceId: forceAnonymousUser
      ? "anonymous"
      : getPriceId(getIsLoggedIn(cookies)),
    ...selectedFilters,
  };

  if (args.pageType === "search") {
    if (q.length === 0) {
      throw Error(`Invalid search query "${q}".`);
    }

    const searchPageArgs: SearchPageQuery = {
      ...commonArgs,
      ...{
        q,
      },
    };

    if (typeOfQuery(q) === ElevateQuery.NORMAL) {
      const result = await getSearchResults({
        api,
        toggle_elevate_cluster_search,
      })(searchPageArgs);

      return {
        responseData: result,
        pageType: "search",
      };
    }

    /**
     * When the search query is other than "NORMAL", the landing page endpoint must be used.
     */
    const result = await getLandingPageResults({
      api,
      toggle_elevate_cluster_landing_page,
    })(searchPageArgs, false);

    return {
      responseData: result,
      pageType: "search",
    };
  }

  if (args.subType === "campaign") {
    const { campaignIds, categoryIds } = args;
    const campaignLandingPageArgs: LandingPageQuery = {
      ...commonArgs,
      campaignIds,
      ...(hasValue(categoryIds) && {
        categoryIds,
      }),
    };

    const result = await getLandingPageResults({
      api,
      toggle_elevate_cluster_landing_page,
    })(campaignLandingPageArgs, false);

    return {
      pageType: "landing",
      responseData: result,
      subType: "campaign",
    };
  }

  if (args.subType === "category") {
    const { longTailFacets, longTailPattern, isDiscountCategoryCode } = args;
    const { selectedLongTailFilters, selectedLongTailFiltersFormatted } =
      hasValue(longTailFacets)
        ? getLongTailFacets(longTailFacets)
        : {
            selectedLongTailFilters: null,
            selectedLongTailFiltersFormatted: null,
          };

    //If we recognize that category is a promo category, we need ask for dynamic pageReference attribute.
    const pageReference = `${isDiscountCategoryCode ? "/dynamic/" : "/"}c/${categoryId?.toString() ?? ""}`;
    const categoryLandingPageArgs: LandingPageQuery = {
      ...commonArgs,
      ...selectedLongTailFilters,
      pageReference,
      q,
    };

    const initialData: LandingPageRequestSettings = {
      primaryList: {
        include: true,
        productRules: `rule incl custom.categoryIds { "${String(categoryId)}" } excl custom.isOnCampaign { "false"}  rule  incl custom.categoryIds { "${String(categoryId)}" } excl custom.isOnPromotion { "false"}`,
      },
    };

    const result = await getLandingPageResults({
      api,
      toggle_elevate_cluster_landing_page,
    })(
      categoryLandingPageArgs,
      true,
      isDiscountCategoryCode ? initialData : undefined
    );

    return {
      longTailFacets,
      longTailPattern,
      pageType: "landing",
      parentCategory: null,
      responseData: result,
      subType: "category",
      selectedLongTailFiltersFormatted,
    };
  }

  throw Error(
    `Invalid page type and/or query. Page type: "${args.pageType}", query: "${q}"`
  );
};

const getAvailability = async ({
  cookies,
  siteUid,
  stores: optionalStores,
}: {
  cookies: Partial<{
    [key: string]: string;
  }>;
  siteUid: EcomSiteUidLegacy;
  stores?: StoreWithId[];
}) => {
  const stores = optionalStores ?? (await getCachedOrFetchedStoreData(siteUid));
  const storeIdsFromApi = stores.map((store) => store.id);
  const preferredStoresCookie = cookies[cookieNames.PREFERRED_STORES];
  const preferredStores =
    preferredStoresCookie !== undefined
      ? parseCookie<PreferredStores>(
          preferredStoresCookie,
          cookieNames.PREFERRED_STORES
        )
      : null;
  const {
    availability: availabilityFromCookie,
    ids: selectedStoresFromCookie,
  } = preferredStores ?? {};
  const storeIdsFromCookie = selectedStoresFromCookie;
  const shouldAddDefaultStoreAvailability = hasNoValue(storeIdsFromCookie);
  const availability: AVAILABILITY[] =
    availabilityFromCookie ??
    (shouldAddDefaultStoreAvailability
      ? DEFAULT_AVAILABILITY
      : [AVAILABILITY.ONLINE]);

  const selectedStores = storeIdsFromCookie ?? storeIdsFromApi;
  const validSelectedStores = shouldAddDefaultStoreAvailability
    ? storeIdsFromApi
    : selectedStores.filter((store) => storeIdsFromApi.includes(store));

  return {
    availability,
    storesData: stores,
    validSelectedStores,
  };
};

async function fetchPageData(
  props: FetchLandingPageData
): Promise<LandingPageResponseData>;
async function fetchPageData(
  props: FetchSearchPageData
): Promise<SearchPageResponseData>;
async function fetchPageData(
  args: FetchLandingPageData | FetchSearchPageData
): Promise<SearchPageResponseData | LandingPageResponseData> {
  const {
    cookies,
    forceAnonymousUser,
    query: pagePropsQuery,
    siteUid,
    stores,
  } = args;
  const { categoryId, query, tab } = pagePropsQuery;
  const { selectedFiltersFormatted } =
    getSelectedFiltersFromUrlParameters(pagePropsQuery);
  const xxlNumberOfColumnsPerDeviceOnPlpCookie =
    cookies[cookieNames.XXL_NUMBER_OF_COLUMNS_PER_DEVICE_ON_PLP];
  const numberOfColumnsPerDeviceOnPlp =
    parseCookie<NumberOfColumnsPerDeviceOnPlp | null>(
      xxlNumberOfColumnsPerDeviceOnPlpCookie ?? "",
      cookieNames.XXL_NUMBER_OF_COLUMNS_PER_DEVICE_ON_PLP
    );
  const { availability, storesData, validSelectedStores } =
    await getAvailability({
      cookies,
      siteUid,
      stores,
    });
  const [searchResponse] = await Promise.allSettled([
    fetchPage({
      ...args,
      forceAnonymousUser,
      availability,
      validSelectedStores,
    }),
  ]);

  const searchResult = getPromiseResult(
    searchResponse,
    "Failed to fetch search results."
  );

  if (searchResult === null) {
    throw Error("No valid response from elevate.");
  }

  if (searchResult.pageType === "landing" && args.pageType === "landing") {
    const clientSafeData = prepareDataForClient(searchResult.responseData.data);
    const { contentLists, navigation, primaryList } = clientSafeData;
    const subCategories = clientSafeData.navigation?.subCategories ?? [];
    const { facets: initialFacets, totalHits } = primaryList;
    const facets = sanitizeFacetsForDisplay(initialFacets);
    const products = primaryList.baseProducts.map(toProductCardDataFromBase);
    const selectedFilters = selectedFiltersFormatted;
    const sortOrderData = primaryList.sort;
    const storeIds = validSelectedStores;
    const tabs = convertContentListsToTabs(contentLists, totalHits);
    const { breadcrumbs: breadcrumbsFromApi = [] } = navigation ?? {};
    const filteredBreadcrumbs = breadcrumbsFromApi.filter(
      hasValidBreadcrumbData
    );
    const mappedBreadcrumbs = filteredBreadcrumbs.map(
      ({ label, seoFriendlyURL: path }) => ({ label, path })
    );
    const { label, path, seoFriendlyURL } =
      filteredBreadcrumbs
        .filter(({ path: p }) => !p.includes(String(categoryId)))
        .at(-1) ?? {};
    const parentCategory =
      hasValue(label) && hasValue(path) && hasValue(seoFriendlyURL)
        ? {
            label,
            path: path.replace("/c/", "/c/v2/"),
            seoFriendlyURL: seoFriendlyURL.replace("/c/", "/c/v2/"),
          }
        : null;
    const emptyFirstBreadcrumb = { label: "", path: "" };
    const breadcrumbs = [emptyFirstBreadcrumb, ...mappedBreadcrumbs];

    if (searchResult.subType === "category") {
      const {
        longTailFacets,
        longTailPattern,
        selectedLongTailFiltersFormatted,
      } = searchResult;

      return {
        ...clientSafeData,
        pageType: "landing",
        autoCorrect: null,
        availability,
        breadcrumbs,
        categoryId: typeof categoryId === "string" ? categoryId : null,
        facets,
        fetchDataClientSide: null,
        isFetchingProducts: false,
        longTailFacets,
        longTailPattern,
        numberOfColumnsPerDeviceOnPlp,
        parentCategory,
        products,
        query: typeof query === "string" && query.length > 0 ? query : null,
        selectedFilters: {
          ...selectedFilters,
          ...selectedLongTailFiltersFormatted,
        },
        sortOrderData,
        storeIds,
        storesData,
        subCategories,
        tabs,
        totalHits,
      };
    }

    return {
      ...clientSafeData,
      pageType: "landing",
      autoCorrect: null,
      availability,
      breadcrumbs,
      categoryId: null,
      facets,
      fetchDataClientSide: null,
      isFetchingProducts: false,
      longTailFacets: null,
      longTailPattern: null,
      numberOfColumnsPerDeviceOnPlp,
      parentCategory: null,
      products,
      query: typeof query === "string" && query.length > 0 ? query : null,
      selectedFilters,
      sortOrderData,
      storeIds,
      storesData,
      subCategories,
      tabs,
      totalHits,
    };
  }

  /**
   * searchResult.pageType === "search"
   */
  const { responseData } = searchResult;
  const { data: _data } = responseData;
  const noProductsFound = isEmpty(_data.primaryList.baseProducts);
  const noOtherContentFound = _data.contentLists.every(({ items }) =>
    isEmpty(items)
  );

  if (noProductsFound && noOtherContentFound) {
    log.info("🙅‍♀️ no results");
    throw new Error("No results found.");
  }
  const clientSafeData = prepareDataForClient(
    searchResult.responseData.data as SearchResultData
  );
  const { contentLists, primaryList } = clientSafeData;
  const { facets: initialFacets, totalHits } = primaryList;
  const facets = sanitizeFacetsForDisplay(initialFacets);
  const products = primaryList.baseProducts.map(toProductCardDataFromBase);
  const selectedFilters = selectedFiltersFormatted;
  const sortOrderData = primaryList.sort;
  const storeIds = validSelectedStores;
  const tabs = convertContentListsToTabs(contentLists, totalHits);
  const selectedTab = isNotNull(tabs)
    ? getCorrectTabNameOrFirstOrNull(tab, tabs, noProductsFound)
    : null;
  const searchSuggestions = prepareSearchSuggestions(
    clientSafeData.relatedSearches
  );

  return {
    ...clientSafeData,
    pageType: "search",
    autoCorrect: clientSafeData.autoCorrect?.q ?? null,
    availability,
    categoryId: null,
    facets,
    fetchDataClientSide: null,
    isFetchingProducts: false,
    longTailFacets: null,
    longTailPattern: null,
    numberOfColumnsPerDeviceOnPlp,
    products,
    query: typeof query === "string" && query.length > 0 ? query : null,
    searchSuggestions,
    selectedFilters,
    selectedTab,
    sortOrderData,
    storeIds,
    storesData,
    tabs,
    totalHits,
  };
}

export { fetchPageData };
