import { prepareContentLists } from "@/react-app/api/elevate-api";
import { getElevateApiConfiguration } from "@/utils/environment-variables";
import { hasValue } from "@xxl/common-utils";
import type {
  LandingPageQuery,
  LandingPageRequestSettings,
  ProductPageQuery,
  SearchPageQuery,
} from "@xxl/product-search-api";
import { ElevateApi, ProductApi } from "@xxl/product-search-api";
import { PRODUCT_API_CLIENT_IDS } from "./constants";

const api = (toggle_elevate_cluster: boolean) =>
  ElevateApi.getInstance(getElevateApiConfiguration(toggle_elevate_cluster));

const productApi = new ProductApi({ dataSources: { elevateApi: api(false) } });

const getSearchResults =
  ({
    toggle_elevate_cluster_search,
    ...props
  }: {
    api?: ElevateApi<unknown>;
    toggle_elevate_cluster_search: boolean;
  }) =>
  async (query: SearchPageQuery) =>
    (props.api ?? api(toggle_elevate_cluster_search)).storefront.searchPagePOST(
      query,
      {
        contentLists: prepareContentLists(query.limit),
      }
    );

const getLandingPageResults =
  ({
    toggle_elevate_cluster_landing_page,
    ...props
  }: {
    api?: ElevateApi<unknown>;
    toggle_elevate_cluster_landing_page: boolean;
  }) =>
  async (
    query: LandingPageQuery,
    includeNavigation: boolean,
    initialData?: LandingPageRequestSettings
  ) =>
    (
      props.api ?? api(toggle_elevate_cluster_landing_page)
    ).storefront.landingPagePOST(query, {
      navigation: {
        include: Boolean(includeNavigation),
      },
      ...(hasValue(initialData) && {
        primaryList: initialData.primaryList,
      }),
    });

const getProductPageResults = async ({
  productKey,
  ...query
}: ProductPageQuery) =>
  productApi.getBaseProduct({
    key: productKey,
    ...query,
    clientId: PRODUCT_API_CLIENT_IDS.getProductPageResults,
  });

export { getLandingPageResults, getProductPageResults, getSearchResults };
