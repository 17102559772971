import { log } from "@xxl/logging-utils";
import { isNotUndefined } from "@xxl/common-utils";

type ReasonProps = {
  message: string;
  request?: {
    path: string;
  };
  response?: {
    data: unknown;
  };
};

export const getPromiseResult = <T>(
  result: PromiseSettledResult<T>,
  errorMessage = `Failed to fetch Promise<${typeof result}>`
): T | null => {
  if (result.status === "rejected") {
    const reason = result.reason as ReasonProps;

    log.debug(errorMessage, reason);

    const logDetails: { message: string; path?: string; response?: unknown } = {
      message: isNotUndefined(reason.message)
        ? reason.message
        : "No message provided",
    };

    if (isNotUndefined(reason.request)) {
      logDetails.path = reason.request.path;
    }

    if (
      isNotUndefined(reason.response) &&
      isNotUndefined(reason.response.data)
    ) {
      logDetails.response = reason.response.data;
    }

    log.error(`${errorMessage}`, logDetails);
    return null;
  }

  return result.value;
};
